@import 'config/variables';
@import 'vendors/include-media';
@import 'components/pseudoicon/mixin';

$category-links-dropdown_trigger-pseudoicon-type: $pseudoicon-type !default;
$category-links-dropdown_trigger-pseudoicon-type-animate: $pseudoicon-animate !default;
$category-links-dropdown_trigger-pseudoicon-animation-time: $pseudoicon-animation-time !default;
$category-links-dropdown_trigger-pseudoicon-color: $color_primary-500 !default;
$category-links-dropdown_trigger-pseudoicon-hover-color: $category-links-dropdown_trigger-pseudoicon-color !default;
$category-links-dropdown_trigger-pseudoicon-expanded-color: $category-links-dropdown_trigger-pseudoicon-color !default;
$category-links-dropdown_trigger-pseudoicon-width: 1rem !default;
$category-links-dropdown_trigger-pseudoicon-height: $pseudoicon-height !default;
$category-links-dropdown_trigger-pseudoicon-side-offset: 0 !default;
$category-links-dropdown_trigger-pseudoicon-left-offset: -0.6rem !default;
$category-links-dropdown_trigger-pseudoicon-active-selector: $pseudoicon-active-selector !default;
// Additional settings for arrows
$category-links-dropdown_trigger-pseudoicon-arrow-default-direction: $pseudoicon-arrow-default-direction !default;
$category-links-dropdown_trigger-pseudoicon-arrow-active-direction: $pseudoicon-arrow-active-direction !default;

.#{$ns}category-links-dropdown {
    $root: &;
    position: relative;

    &__trigger {
        @include pseudoicon(
            $type: $category-links-dropdown_trigger-pseudoicon-type,
            $animated: $category-links-dropdown_trigger-pseudoicon-type-animate,
            $animation-time:
                $category-links-dropdown_trigger-pseudoicon-animation-time,
            $color: $category-links-dropdown_trigger-pseudoicon-color,
            $color-hover:
                $category-links-dropdown_trigger-pseudoicon-hover-color,
            $color-expanded:
                $category-links-dropdown_trigger-pseudoicon-expanded-color,
            $width: $category-links-dropdown_trigger-pseudoicon-width,
            $height: $category-links-dropdown_trigger-pseudoicon-height,
            $position-side-offset:
                $category-links-dropdown_trigger-pseudoicon-side-offset,
            $arrow-default:
                $category-links-dropdown_trigger-pseudoicon-arrow-default-direction,
            $arrow-active:
                $category-links-dropdown_trigger-pseudoicon-arrow-active-direction,
            $arrow-offset:
                $category-links-dropdown_trigger-pseudoicon-left-offset,
            $active-selector:
                $category-links-dropdown_trigger-pseudoicon-active-selector
        );

        &[aria-expanded='true'] {
            position: relative;
            z-index: 5;
            border-color: transparent;

            @include media('>=tablet') {
                position: static;
                z-index: auto;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: -1rem;
        right: -1rem;
        width: auto;
        padding-top: 4.8rem;
        overflow: hidden;
        height: 0;
        opacity: 0;
        transform: translateY(4.8rem);
        transition: opacity 50ms linear, transform 100ms ease-in-out;
        display: block !important;

        @include media('>=tablet') {
            position: static;
            overflow: visible;
            height: auto;
            opacity: 1;
            transform: none;
            padding: 0;
            transition: none;

            @include media('>=tablet') {
                display: flex !important;
            }
        }

        &[aria-hidden='false'] {
            z-index: 4;
            height: auto;
            opacity: 1;
            transform: translateY(0);
        }
    }

    &--no-subcats {
        #{$root}__content {
            @include media('<tablet') {
                position: static;
                transform: none;
                height: auto;
                opacity: 1;
                box-shadow: none;
                padding: 0;
            }
        }
    }
}
