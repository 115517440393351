/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
@use 'sass:map';
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-category-links-dropdown {
  position: relative; }
  .cs-category-links-dropdown__trigger {
    position: relative; }
    .cs-category-links-dropdown__trigger:before, .cs-category-links-dropdown__trigger:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 1rem;
      height: 0.2rem;
      left: auto;
      background-color: #7e57c2;
      transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-category-links-dropdown__trigger:before, .cs-category-links-dropdown__trigger:after {
      right: 0;
      transform: translate3d(0, -50%, 0) !important; }
    .cs-category-links-dropdown__trigger:after {
      transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
    .cs-category-links-dropdown__trigger[aria-expanded="true"]:before {
      transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
      opacity: 0; }
    .cs-category-links-dropdown__trigger[aria-expanded="true"]:after {
      transform: translate3d(0, -50%, 0) rotate(0) !important; }
    .cs-category-links-dropdown__trigger[aria-expanded='true'] {
      position: relative;
      z-index: 5;
      border-color: transparent; }
      @media (min-width: 48em) {
        .cs-category-links-dropdown__trigger[aria-expanded='true'] {
          position: static;
          z-index: auto; } }
  .cs-category-links-dropdown__content {
    position: absolute;
    top: 0;
    left: -1rem;
    right: -1rem;
    width: auto;
    padding-top: 4.8rem;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transform: translateY(4.8rem);
    transition: opacity 50ms linear, transform 100ms ease-in-out;
    display: block !important; }
    @media (min-width: 48em) {
      .cs-category-links-dropdown__content {
        position: static;
        overflow: visible;
        height: auto;
        opacity: 1;
        transform: none;
        padding: 0;
        transition: none; } }
  @media (min-width: 48em) and (min-width: 48em) {
    .cs-category-links-dropdown__content {
      display: flex !important; } }
    .cs-category-links-dropdown__content[aria-hidden='false'] {
      z-index: 4;
      height: auto;
      opacity: 1;
      transform: translateY(0); }
  @media (max-width: 47.99em) {
    .cs-category-links-dropdown--no-subcats .cs-category-links-dropdown__content {
      position: static;
      transform: none;
      height: auto;
      opacity: 1;
      box-shadow: none;
      padding: 0; } }
